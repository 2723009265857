// components/LoadingIndicator.tsx
import React from 'react';
import '../styles/LoadingIndicator.css';

const LoadingIndicator: React.FC = () => (
  <div className="loading-indicator">
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
  </div>
);

export default LoadingIndicator;