// components/NotificationsComponent.tsx
import React, { useState, useEffect } from 'react';

import NotificationItem from './NotificationItem';
import PaginationNotifications from './PaginationNotifications';
import '../styles/Panel.css';
import { useNotifications } from '../context/NotificationsContext';

interface NotificationsProps {
  limited?: boolean; // for showing only 7 notifications in the navbar dropdown
}

const NotificationsComponent: React.FC<NotificationsProps> = ({ limited = false }) => {
  const { fetchNotifications, notifications, totalNotificationPages } = useNotifications();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchNotifications(currentPage);
  }, [currentPage, fetchNotifications]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="notifications-container">
      <h1>Pranešimai</h1>
      <div className={limited ? 'notifications-container-list-navbar' : 'notifications-container-list'}>
        {notifications.map((notification) => (
          <NotificationItem key={notification.id} notification={notification} limited={limited} />
        ))}
      </div>

      {limited ? (
        <div className="show-more">
          <a href="/vartotojas/skydelis">Rodyti visas žinutes</a>
        </div>
      ) : (
        <PaginationNotifications
          totalPageCount={totalNotificationPages}
          onPageChange={handlePageChange}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default NotificationsComponent;