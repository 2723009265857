import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import logo from '../assets/images/Logo-02-cropped.svg';
import logoWhite from '../assets/images/Logo-03.svg';
import logoSmall from '../assets/images/Logo-14.svg';

type LogoProps = {
  showSearchBar: boolean;
  isScrolled: boolean;
};

const Logo: React.FC<LogoProps> = ({ showSearchBar, isScrolled }) => {
  const navigate = useNavigate();
  const isBigScreen = useMediaQuery({ query: '(min-width: 660px)' });

  const handleNavigate = () => {
    navigate('/');
  };

  let logoSrc;
  let buttonClass;

  if (!isBigScreen) {
    // Mobile logic
    logoSrc = showSearchBar ? logoSmall : (isScrolled ? logo : logoWhite);
    buttonClass = showSearchBar
      ? 'logo-button-small'
      : (isScrolled ? 'logo-button' : 'logo-button');
  } else {
    // Desktop logic
    logoSrc = showSearchBar ? logo : (isScrolled ? logo : logoWhite);
    buttonClass = 'logo-button';
  }

  return (
    <section className="navigation">
      <button id="/" className={buttonClass} onClick={handleNavigate}>
        <img src={logoSrc} alt="Logo" />
      </button>
    </section>
  );
};

export default Logo;