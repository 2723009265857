// context/SearchContext.tsx
import React, { createContext, useState, ReactNode } from 'react';

interface SearchContextProps {
    searchResults: any[];
    setSearchResults: (results: any[]) => void;
}

interface SearchProviderProps {
    children: ReactNode;
}

export const SearchContext = createContext<SearchContextProps>({
    searchResults: [],
    setSearchResults: () => {},
});

export const SearchProvider: React.FC<SearchProviderProps> = ({ children }) => {
    const [searchResults, setSearchResults] = useState<any[]>([]);

    return (
        <SearchContext.Provider value={{ searchResults, setSearchResults }}>
            {children}
        </SearchContext.Provider>
    );
};
