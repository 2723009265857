// components/NotificationItem.tsx
import React from 'react';
import { Notification } from '../types';
import DOMPurify from 'dompurify';
import { format } from 'date-fns';
import lt from 'date-fns/locale/lt';
import { useNavigate } from 'react-router-dom';


interface NotificationItemProps {
  notification: Notification;
  limited: boolean;
}

const NotificationItem: React.FC<NotificationItemProps> = ({ notification, limited }) => {
  const sanitizedHTML = DOMPurify.sanitize(notification.description);
  const formattedDate = format(new Date(notification.createdAt), 'MMM dd', { locale: lt });
  const formattedTime = format(new Date(notification.createdAt), 'HH:mm', { locale: lt });
  const navigate = useNavigate();

   // Define a function to limit the character count
   const limitText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  // Apply the character limit to the sanitized HTML
  const limitedHTML = limitText(sanitizedHTML, 75);

  return (
    <div className={`notification-item ${!notification.read ? 'unread' : ''}`} onClick={() => {limited ? navigate(`/vartotojas/skydelis`) : navigate(`/skelbimai/${notification.listingId}`)}}>
      <div className="notification-timestamp">
        <span className="date">{formattedDate}</span>
        <span className="time">{formattedTime}</span>
      </div>
      {limited ?
        <a className="notification-link" dangerouslySetInnerHTML={{ __html: limitedHTML }} />
      :
        <a className="notification-link" dangerouslySetInnerHTML={{ __html: sanitizedHTML}} />
      }
      
      <p>▸</p>
    </div>
  );
};

export default React.memo(NotificationItem);