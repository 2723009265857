// components/Footer.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import '../styles/Footer.css';
import fbIcon from '../assets/images/footer_fb.svg';
import igIcon from '../assets/images/footer_ig.svg';
import inIcon from '../assets/images/footer_in.svg';


const Footer = () => {
    const navigate = useNavigate();

    const handleNavigation = (path: string) => {
        navigate(path);
        window.scrollTo(0, 0); // scroll to top after
    };

    return (
        <footer className="footer">
            <div className="footer-section">
                <h2>Apie mus</h2>
                <ul>
                    <li onClick={() => handleNavigation('/apie-mus#ourvision')}>Kas mes?</li>
                    <li onClick={() => handleNavigation('/apie-mus#ourvision')}>Mūsų vizija</li>
                    <li onClick={() => handleNavigation('/straipsniai#')}>Straipsniai</li>
                </ul>
            </div>
            {/* useful after production, to get SEO points */}
            <div className="footer-section">
                <h2>Straipsniai</h2>
                <ul>
                    <li onClick={() => handleNavigation('/straipsniai')}>Visi</li>
                    <li onClick={() => handleNavigation('/straipsniai/kam-tie-korepetitoriai')}>Kam tie korepetitoriai?</li>
                </ul>
            </div>
            <div className="footer-section">
                <h2>Pagalba</h2>
                <ul>
                    <li onClick={() => handleNavigation('/apie-mus#contactus')}>Kontaktai</li>
                    <li onClick={() => handleNavigation('/apie-mus#contactus')}>Nerandate savo kategorijos?</li>
                </ul>
            </div>
            <div className="footer-section">
                <h2>Soc. tinklai</h2>
                <div className="social-links">
                    <a href="https://facebook.com/tutoriai.lt" aria-label="Facebook">
                        <LazyLoadImage
                            src={fbIcon}
                            alt="Facebook"
                            effect="opacity"
                            width={40}
                            height={40}
                        />
                    </a>
                    <a href="https://instagram.com/tutoriai.lt" aria-label="Instagram">
                        <LazyLoadImage
                            src={igIcon}
                            alt="Instagram"
                            effect="opacity"
                            width={40}
                            height={40}
                        />
                    </a>
                    <a href="https://linkedin.com/company/tutoriai" aria-label="LinkedIn">
                        <LazyLoadImage
                            src={inIcon}
                            alt="LinkedIn"
                            effect="opacity"
                            width={40}
                            height={40}
                        />
                    </a>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="footer-left">
                    <p>© 2023 Tutoriai •
                        <span onClick={() => handleNavigation('/naudojimosi-taisykles')}>Naudojimo Sąlygos</span> •
                        <span onClick={() => handleNavigation('/privatumo-politika')}>Privatumo Politka</span>
                    </p>
                </div>
                <div className="footer-right">
                    <p className="recaptcha-notice">
                        Saugoma reCAPTCHA ir Google. Taikoma &nbsp;
                        <a href="https://policies.google.com/privacy">Privatumo Politika</a> ir &nbsp;
                        <a href="https://policies.google.com/terms">Naudojimosi Sąlygos</a>.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
