// components/Search.tsx
import React, { useContext, useState, useEffect, useRef } from "react";
import { SearchContext } from "../context/SearchContext";
import { useAuthApi } from "../utils/authApi";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { useMediaQuery } from "react-responsive";
import LoadingIndicator from "../components/LoadingIndicator";

// Styling
import "../styles/Search.css";

type SearchProps = {
  isInNavbar?: boolean;
};
let hideDropdownTimeout: NodeJS.Timeout;

const Search: React.FC<SearchProps> = ({ isInNavbar = false }) => {
  const { searchResults, setSearchResults } = useContext(SearchContext);
  const [searchInput, setSearchInput] = useState("");
  const [hoveredResult, setHoveredResult] = useState(0);
  const [popularKeywords, setPopularKeywords] = useState<any[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchResultsLoading, setSearchResultsLoading] = useState(false);
  const [listingsCount, setListingsCount] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const resultsRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const navigate = useNavigate();
  const { get } = useAuthApi();

  const isBigScreen = useMediaQuery({ query: "(min-width: 470px)" });

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (showSuggestions) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setHoveredResult((prev) =>
          Math.min(
            prev + 1,
            searchInput ? searchResults.length - 1 : popularKeywords.length - 1
          )
        );
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setHoveredResult((prev) => Math.max(prev - 1, 0));
      } else if (event.key === "Enter" && hoveredResult >= 0) {
        event.preventDefault();
        if (hoveredResult >= 0) {
          const selectedItem = searchInput
            ? searchResults[hoveredResult]
            : popularKeywords[hoveredResult];
          if (selectedItem) {
            handleSelectResult(selectedItem);
          } else {
            if (searchInput === "") {
              navigateTo(`/skelbimai`);
            } else {
              navigateTo(`/skelbimai?search=${searchInput}`);
            }
          }
        }
      }
    }
  };

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (searchInput === "") {
      navigateTo(`/skelbimai`);
    } else if (searchResults.length > 0) {
      const firstResult = searchResults[0];
      navigateTo(
        `/skelbimai?top=${firstResult.topLevelKeyword.name}&main=${firstResult.name}`
      );
    } else {
      navigateTo(`/skelbimai?search=${searchInput}`);
    }
  };

  const handleSelectResult = (result: any) => {
    navigateTo(
      `/skelbimai?top=${result.topLevelKeyword.name}&main=${result.name}`
    );
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const navigateTo = (newSearch: string) => {
    const currentUrl = new URL(window.location.href);
    const newUrl = new URL(newSearch, currentUrl.origin);

    const isCurrentListings =
      currentUrl.pathname === "/skelbimai" ||
      currentUrl.pathname === "/skelbimai/";
    const isNewListings =
      newUrl.pathname === "/skelbimai" || newUrl.pathname === "/skelbimai/";

    if (isCurrentListings && isNewListings) {
      navigate(newSearch, { replace: true });
    } else {
      navigate(newSearch);
    }
  };

  const fetchResults = (input: string) => {
    get(`/api/search/${input}`, {}, { requiresAuth: false })
      .then((response) => {
        setSearchResults(response.data);
      })
      .catch((error) => {
        console.error("Error fetching search results", error);
      })
      .finally(() => {
        setSearchResultsLoading(false);
      });
  };
  const debouncedFetchResults = React.useCallback(
    debounce(fetchResults, 500),
    []
  );

  const hideDropdown = () => {
    clearTimeout(hideDropdownTimeout);
    hideDropdownTimeout = setTimeout(() => {
      setShowSuggestions(false);
    }, 150);
  };

  const loadInitialData = async () => {
    if (!dataLoaded) {
      setIsLoading(true);
      try {
        const [popularResponse, countResponse] = await Promise.all([
          get("/api/search/popular", {}, { requiresAuth: false }),
          get("/api/search/listings/count", {}, { requiresAuth: false })
        ]);
        setPopularKeywords(popularResponse.data);
        setListingsCount(countResponse.data.count);
        setDataLoaded(true);
      } catch (error) {
        console.error("Error fetching initial data", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (searchInput.length >= 1) {
      setSearchResultsLoading(true);
      debouncedFetchResults(searchInput);
    }
  }, [searchInput, debouncedFetchResults]);

  useEffect(() => {
    setHoveredResult(0);
  }, [searchResults]);



  return (
    <div
      className={`${
        isInNavbar ? "navbar-search-wrapper" : "home-search-wrapper"
      }`}
    >
      <form onSubmit={handleSearch} className="input-search">
        <input
          className={`${isInNavbar ? "navbar-search" : "home-search"}`}
          placeholder={
            isBigScreen
              ? "Kurioje srityje norite tobulėti?"
              : "Ieškoti tutorių..."
          }
          onChange={handleSearchInput}
          onFocus={() => {
            clearTimeout(hideDropdownTimeout);
            setShowSuggestions(true);
            loadInitialData();
          }}
          onBlur={() => {
            hideDropdown();
          }}
          value={searchInput}
          onKeyDown={handleKeyDown}
          ref={inputRef}
        />
        <button
          type="submit"
          className={`${
            isInNavbar ? "navbar-search-button" : "home-search-button"
          }`}
        >
          Ieškoti
        </button>
      </form>
      <div
        className={`results ${isInNavbar ? "navbar" : "home"} ${
          showSuggestions ? "show" : ""
        }`}
        ref={resultsRef as React.RefObject<HTMLDivElement>}
      >
        {isLoading || searchResultsLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            {showSuggestions &&
              !searchInput &&
              popularKeywords.map((keyword, index) => (
                <div
                  key={keyword.id}
                  onClick={() => handleSelectResult(keyword)}
                  onMouseEnter={() => setHoveredResult(index)}
                  className={
                    index === hoveredResult
                      ? "default-selection-hovered"
                      : "default-selection"
                  }
                >
                  <strong>{keyword.topLevelKeyword.name}</strong> ⟶{" "}
                  {keyword.name}
                </div>
              ))}
            {searchInput &&
              searchResults.slice(0, 4).map((result, index) => (
                // ... up to 4 search results ...
                <div
                  key={result.id}
                  onClick={() => handleSelectResult(result)}
                  onMouseEnter={() => setHoveredResult(index)}
                  className={
                    index === hoveredResult
                      ? "default-selection-hovered"
                      : "default-selection"
                  }
                >
                  <strong>{result.topLevelKeyword.name}</strong> ⟶ {result.name}
                </div>
              ))}
            {searchInput && searchResults.length === 0 && (
              <div className="unique-search-suggestion">
                Ieškoti tarp {listingsCount} skelbimų pagal "{searchInput}"...
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default Search;
