// layouts/NavbarAndFooter.tsx
import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const NavbarAndFooter: React.FC = () => {
    const location = useLocation();
    const [showSearchBar, setShowSearchBar] = useState(location.pathname !== '/');

    useEffect(() => {
        setShowSearchBar(location.pathname !== '/');
    }, [location.pathname]);
    return (
        <>
            <Navbar showSearchBar={showSearchBar} />
            <main>
                <Outlet />
            </main>
            <Footer />
        </>
    );
};

export default NavbarAndFooter;