// utils/authApi.ts
import axios from 'axios';
import { useCallback } from 'react';
import { useAuth } from '../context/AuthStatusContext';
import { executeRecaptcha } from './recaptchaUtils';

export function useAuthApi() {
    const { isLoggedIn } = useAuth();

    const attachRecaptchaToken = async (action: string = 'submit') => {
        const token = await executeRecaptcha(action);
        return token;
    };

    const handleRequest = useCallback(async (fn: () => Promise<any>, requiresAuth: boolean = true) => {
        if (requiresAuth && !isLoggedIn) {
            throw new Error('Request requires authentication');
        }
        try {
            return await fn();
        } catch (error) {
            throw error;
        }
    }, [isLoggedIn]);

    const post = useCallback(async (url: string, data: any, options: { requiresAuth?: boolean, useRecaptcha?: boolean, recaptchaAction?: string } = {}) => {
        let headers: any = {};

        if (options.useRecaptcha) {
            const recaptchaToken = await attachRecaptchaToken(options.recaptchaAction);
            headers['g-recaptcha-response'] = recaptchaToken;
        }

        return handleRequest(() => axios.post(url, data, {
            headers: headers,
            withCredentials: true,
        }), options.requiresAuth);

    }, [handleRequest]);

    const get = useCallback(async (url: string, queryParams?: any, options: { requiresAuth?: boolean } = {}) => {
        return handleRequest(() => axios.get(url, {
            params: queryParams,
            withCredentials: true,
        }), options.requiresAuth);
    }, [handleRequest]);

    const deleteRequest = useCallback(async (url: string, data?: any) => {
        return handleRequest(() => axios.delete(url, {
            data,
            withCredentials: true,
        }));
    }, [handleRequest]);

    const put = useCallback(async (url: string, data: any, options: { useRecaptcha?: boolean, recaptchaAction?: string }) => {
        let headers: any = {};

        if (options.useRecaptcha) {
            const recaptchaToken = await attachRecaptchaToken(options.recaptchaAction);
            headers['g-recaptcha-response'] = recaptchaToken;
        }

        return handleRequest(() => axios.put(url, data, {
            headers: headers,
            withCredentials: true,
        }));
    }, [handleRequest]);

    const patch = useCallback(async (url: string, data: any, options: { useRecaptcha?: boolean, recaptchaAction?: string }) => {
        let headers: any = {};

        if (options.useRecaptcha) {
            const recaptchaToken = await attachRecaptchaToken(options.recaptchaAction);
            headers['g-recaptcha-response'] = recaptchaToken;
        }

        return handleRequest(() => axios.patch(url, data, {
            headers: headers,
            withCredentials: true,
        }));
    }, [handleRequest]);

    return {
        post,
        get,
        delete: deleteRequest,
        put,
        patch
    };
}