// utils/recaptchaUtils.ts
declare const grecaptcha: any;

export const executeRecaptcha = async (action = 'submit'): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        grecaptcha.ready(() => {
            try {
                grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action })
                    .then((token: string) => {
                        resolve(token);
                    })
                    .catch((error: any) => {
                        console.error("reCAPTCHA error:", error);
                        reject(error);
                    });
            } catch (error) {
                console.error("reCAPTCHA error:", error);
                reject(error);
            }
        });
    });
};
