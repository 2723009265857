// components/NavbarMobileMenu.tsx
import React, { useState } from 'react';
import BurgerMenu from './Hamburger';

type MobileMenuProps = {
  unreadNotificationsCount: number;
  showSearchBar: boolean;
  isScrolled: boolean;
};

const MobileMenu: React.FC<MobileMenuProps> = ({
  unreadNotificationsCount,
  showSearchBar,
  isScrolled,
}) => {
  const [mobMenu, setMobMenu] = useState(false);

  const handleMenuClose = () => {
    setMobMenu(false);
  };

  return (
    <section className="hamburger-section">
      {unreadNotificationsCount > 0 && (
        <h4 className="unread-badge burger">
          {unreadNotificationsCount}
        </h4>
      )}
      <BurgerMenu
        open={mobMenu}
        onClose={handleMenuClose}
        whiteLines={showSearchBar ? false : !isScrolled}
        unreadNotificationsCount={unreadNotificationsCount}
      >
        <></>
      </BurgerMenu>
    </section>
  );
};

export default MobileMenu;