// components/CookiesBar.tsx
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import closeImg from "../assets/images/close-button-x.svg";
import "../styles/CookiesBar.css";

const CookiesBar = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const [showBanner, setShowBanner] = useState(!cookies.cookieConsent);

  useEffect(() => {
    // Set the cookie once the user accepts the cookie policy
    if (!showBanner) {
      setCookie("cookieConsent", true, { path: "/" });
    }
  }, [showBanner, setCookie]);

  const handleAccept = () => {
    setShowBanner(false);
  };

  return (
    <>
      {showBanner  && (
        <div className="cookies-bar">
          <p>Naudodamiesi šiuo tinklalapiu, Jūs pritariate jo slapukų naudojimui.</p>
          <button onClick={handleAccept}>
            <img src={closeImg} alt="close" />
          </button>
        </div>
      )}
    </>
  );
};

export default CookiesBar;
