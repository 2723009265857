import mapboxgl from 'mapbox-gl';

export const validLatLng = (listing: any) =>
  typeof listing.latitude === 'number' &&
  typeof listing.longitude === 'number' &&
  !isNaN(listing.latitude) &&
  !isNaN(listing.longitude);

/**
 * Converts mapboxgl.LngLatBounds to a 2D array format.
 * @param {mapboxgl.LngLatBounds | null} bounds - The map bounds to convert.
 * @returns {Array<Array<number>> | null} - The converted bounds in array format, or null if no bounds are provided.
 */
export const convertBoundsToArray = (bounds: mapboxgl.LngLatBounds | null): Array<Array<number>> | null => {
  if (!bounds) return null;
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  return [[sw.lng, sw.lat], [ne.lng, ne.lat]];
};