// components/NavbarModal.tsx
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

type NavbarModalProps = {
  setShowNavbarModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>;
  unreadNotificationsCount: number;
  handleLogout: () => void;
};

const NavbarModal: React.FC<NavbarModalProps> = ({
  setShowNavbarModal,
  setDropdownVisible,
  unreadNotificationsCount,
  handleLogout,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleModalItemClick = (action: () => void) => {
    action();
    setShowNavbarModal(false);
  };

  return (
    <div
      className="navbar-popup-overlay"
      onClick={() => setShowNavbarModal(false)}
    >
      <div
        className="navbar-modal-section"
        onClick={(event) => event.stopPropagation()}
      >
        <span
          onClick={() => handleModalItemClick(() => setDropdownVisible(true))}
        >
          Pranešimai
          {unreadNotificationsCount > 0 && (
            <h4 className="unread-badge-dropdown">
              {unreadNotificationsCount}
            </h4>
          )}
        </span>
        <span
          onClick={() => handleModalItemClick(() => navigate('/vartotojas/mano-skelbimai'))}
        >
          Mano skelbimai
        </span>
        <span
          onClick={() => handleModalItemClick(() => navigate('/vartotojas'))}
        >
          Paskyra
        </span>
        <span
          onClick={() => handleModalItemClick(() => {
            if (location.pathname === '/skelbimai/paskelbti') {
              window.location.href = '/skelbimai/paskelbti';
            } else {
              navigate('/skelbimai/paskelbti');
            }
          })}
        >
          Įdėti skelbimą
        </span>
        <span
          onClick={() => handleModalItemClick(() => navigate('/apie-mus#contactus'))}
        >
          Pagalba
        </span>
        <hr />
        <span style={{ opacity: 0.6 }} onClick={() => handleModalItemClick(handleLogout)}>
          Atsijungti
        </span>
      </div>
    </div>
  );
};

export default NavbarModal;