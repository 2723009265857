// context/NotificationsContext.tsx
import React, { createContext, useContext, useState, useCallback } from 'react';
import { Notification } from '../types';
import { useAuthApi } from '../utils/authApi';

interface NotificationsContextProps {
  notifications: Notification[];
  loadingNotifications: boolean;
  fetchNotifications: (page: number) => Promise<void>;
  totalNotifications: number;
  totalNotificationPages: number;
  unreadNotificationsCount: number;
  updateAllToRead: () => Promise<void>;
}

const NotificationsContext = createContext<NotificationsContextProps | undefined>(undefined);

export const NotificationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [totalNotificationPages, setTotalNotificationPages] = useState(0);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

  const authApi = useAuthApi();

  const fetchNotifications = useCallback(async (page: number = 1) => {
    // setLoadingNotifications(true);
    try {
      const response = await authApi.get('/api/notifications', {
        withCredentials: true,
        params: { page }
      });
      setNotifications(response.data.notifications);
      setTotalNotifications(response.data.totalNotifications);
      setTotalNotificationPages(response.data.totalNotificationPages);
      setUnreadNotificationsCount(response.data.unreadCount);
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setNotifications([]);
    } finally {
      setLoadingNotifications(false);
    }
  }, []);

  const updateAllToRead = async () => {
    try {
      const notificationIds = notifications.map(notification => notification.id);
      await authApi.put('/api/notifications/mark-read', { ids: notificationIds }, { useRecaptcha: false });
      setUnreadNotificationsCount(0);
    } catch (error) {
      console.error("Error updating notifications to read:", error);
    }
  };

  return (
    <NotificationsContext.Provider value={{
      notifications, loadingNotifications, fetchNotifications,
      totalNotifications, totalNotificationPages, unreadNotificationsCount,
      updateAllToRead
    }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationsProvider');
  }
  return context;
};