// components/Navbar.tsx
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthStatusContext';
import { useNotifications } from '../context/NotificationsContext';
import { useMediaQuery } from 'react-responsive';

import Logo from './NavbarLogo';
import Search from './Search';
import DesktopMenu from './NavbarDesktopMenu';
import MobileMenu from './NavbarMobileMenu';
import NotificationsDropdown from './NavbarNotificationsDropdown';

import '../styles/Navbar.css';

type NavbarProps = {
  showSearchBar?: boolean;
};

const Navbar: React.FC<NavbarProps> = ({ showSearchBar = true }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [showNavbarModal, setShowNavbarModal] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const { isLoggedIn, loadingAuth } = useAuth();
  const { unreadNotificationsCount, updateAllToRead } = useNotifications();

  const isBigScreen = useMediaQuery({ query: '(min-width: 660px)' });
  const showAboutUs = useMediaQuery({ query: '(min-width: 880px)' });

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY >= window.innerHeight - 70);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navbarClass = showSearchBar
    ? 'not-home-page-navbar'
    : isScrolled
    ? 'home-page-navbar-scrolled'
    : 'home-page-navbar';

  if (loadingAuth) {
    return <div className={navbarClass} style={{ height: '70px' }} />; // prevent layout shift
  }

  return (
    <nav className={navbarClass}>
      <Logo showSearchBar={showSearchBar} isScrolled={isScrolled} />
      
      {showSearchBar && (
        <div className="navbar-search-section">
          <Search isInNavbar={true} />
        </div>
      )}
      
      {isBigScreen ? (
        <DesktopMenu
          isLoggedIn={isLoggedIn}
          showSearchBar={showSearchBar}
          isScrolled={isScrolled}
          showAboutUs={showAboutUs}
          unreadNotificationsCount={unreadNotificationsCount}
          setShowNavbarModal={setShowNavbarModal}
          showNavbarModal={showNavbarModal}
          setDropdownVisible={setDropdownVisible}
        />
      ) : (
        <MobileMenu
          unreadNotificationsCount={unreadNotificationsCount}
          showSearchBar={showSearchBar}
          isScrolled={isScrolled}
        />
      )}

      {dropdownVisible && (
        <NotificationsDropdown
          setDropdownVisible={setDropdownVisible}
          updateAllToRead={updateAllToRead}
        />
      )}
    </nav>
  );
};

export default Navbar;