// utils/axiosSetup.ts
import axios from 'axios';

export function setupAxiosInterceptors(logout: () => void) {
    axios.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error.response?.status === 401) {  // unauthorized
            logout();
        }
        return Promise.reject(error);
    });
}