// CityContext.tsx
import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { fetchCities } from '../utils/filterCallsHTTP';
import { City } from '../types';

interface CityProviderProps {
    children: ReactNode;
}

const CityContext = createContext<{ cities: City[] }>({ cities: [] });

export const useCities = () => {
  const context = useContext(CityContext);
  if (!context) {
    throw new Error('useCities must be used within a CityProvider');
  }
  return context;
};

export const CityProvider: React.FC<CityProviderProps> = ({ children }) => {
    const [cities, setCities] = useState<City[]>([]);
  
    useEffect(() => {
        const fetchAvailableCities = async () => {
            try {
                const fetchedCities = await fetchCities();
                setCities(fetchedCities);
            } catch (err) {
                console.error(err);
            }
        };

    fetchAvailableCities();
  }, []);

  return (
    <CityContext.Provider value={{ cities }}>
      {children}
    </CityContext.Provider>
  );
};
