// context/FilterContext.tsx
import React, { createContext, useContext, useState } from 'react';
export const MIN_PRICE = 0;
export const MAX_PRICE = 50;
export const MIN_RATING = 0;


interface FilterContextProps {
  minPrice: number;
  maxPrice: number;
  minRating: number;
  topLevelKeyword: string | null;
  keyword: string | null;
  lowLevelKeywords: string[] | null;
  cityId: number | null;
  locationIds: number[] | null;
  isBusiness: boolean | null;
  uniqueEntry: string | null;
  currentBounds: mapboxgl.LngLatBounds | null;
  setTopLevelKeyword: (keyword: string | null) => void;
  setKeyword: (keyword: string | null) => void;
  setLowLevelKeywords: (keywords: string[] | null) => void;
  setPriceRange: (minPrice: number, maxPrice: number) => void;
  setRatingRange: (minRating: number) => void;
  setCityId: (city: number | null) => void;
  setLocationIds: (location: number[] | null) => void;
  setIsBusiness: (business: boolean | null) => void;
  setUniqueEntry: (entry: string | null) => void;
  setCurrentBounds: (currentBounds: mapboxgl.LngLatBounds | null) => void;
}

const FilterContext = createContext<FilterContextProps>({
  minPrice: MIN_PRICE,
  maxPrice: MAX_PRICE,
  minRating: MIN_RATING,
  topLevelKeyword: null,
  keyword: null,
  lowLevelKeywords: null,
  cityId: null,
  locationIds: null,
  isBusiness: null,
  uniqueEntry: null,
  currentBounds: null,
  setTopLevelKeyword: () => { },
  setKeyword: () => { },
  setLowLevelKeywords: () => { },
  setPriceRange: () => { },
  setRatingRange: () => { },
  setCityId: () => { },
  setLocationIds: () => { },
  setIsBusiness: () => { },
  setUniqueEntry: () => { },
  setCurrentBounds: () => { },
});

interface FilterState {
  minPrice: number;
  maxPrice: number;
  minRating: number;
  topLevelKeyword: string | null;
  keyword: string | null;
  lowLevelKeywords: string[] | null;
  cityId: number | null;
  locationIds: number[] | null;
  isBusiness: boolean | null;
  uniqueEntry: string | null;
  currentBounds: mapboxgl.LngLatBounds | null;
}

interface FilterProviderProps {
  children: React.ReactNode;
}

export const useFilters = () => useContext(FilterContext);

export const FilterProvider: React.FC<FilterProviderProps> = ({ children }) => {
  const [filterState, setFilterState] = useState<FilterState>({
    minPrice: MIN_PRICE,
    maxPrice: MAX_PRICE,
    minRating: MIN_RATING,
    topLevelKeyword: null,
    keyword: null,
    lowLevelKeywords: null,
    cityId: null,
    locationIds: null,
    isBusiness: null,
    uniqueEntry: null,
    currentBounds: null,
  });

  const setPriceRange = (minPrice: number, maxPrice: number) => {
    setFilterState(prev => ({ ...prev, minPrice, maxPrice }));
  };

  const setRatingRange = (minRating: number) => {
    setFilterState(prev => ({ ...prev, minRating }));
  };

  const setTopLevelKeyword = (keyword: string | null) => {
    setFilterState(prev => ({ ...prev, topLevelKeyword: keyword }));
  };

  const setKeyword = (keyword: string | null) => {
    setFilterState(prev => ({ ...prev, keyword }));
  };

  const setLowLevelKeywords = (keywords: string[] | null) => {
    setFilterState(prev => ({ ...prev, lowLevelKeywords: keywords }));
  };

  const setCityId = (cityId: number | null) => {
    setFilterState(prev => ({ ...prev, cityId }));
  };

  const setLocationIds = (locationIds: number[] | null) => {
    setFilterState(prev => ({ ...prev, locationIds }));
  };

  const setIsBusiness = (isBusiness: boolean | null) => {
    setFilterState(prev => ({ ...prev, isBusiness }));
  };

  const setUniqueEntry = (uniqueEntry: string | null) => {
    setFilterState(prev => ({ ...prev, uniqueEntry }));
  };

  const setCurrentBounds = React.useCallback((currentBounds: mapboxgl.LngLatBounds | null) => {
    setFilterState(prev => ({ ...prev, currentBounds }));
  }, []);

  return (
    <FilterContext.Provider value={{ ...filterState, setPriceRange, setRatingRange, setTopLevelKeyword, setKeyword, setLowLevelKeywords, setCityId, setLocationIds, setIsBusiness, setUniqueEntry, setCurrentBounds }}>
      {children}
    </FilterContext.Provider>
  );
};
