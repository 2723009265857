// components/NavbarDesktopMenu.tsx
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthStatusContext';
import NavbarModal from './NavbarModal';

import man from "../assets/images/personal.svg";
import manuImg from "../assets/images/menu.svg";



type DesktopMenuProps = {
  isLoggedIn: boolean;
  showSearchBar: boolean;
  isScrolled: boolean;
  showAboutUs: boolean;
  unreadNotificationsCount: number;
  setShowNavbarModal: React.Dispatch<React.SetStateAction<boolean>>;
  showNavbarModal: boolean;
  setDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const DesktopMenu: React.FC<DesktopMenuProps> = ({
  isLoggedIn,
  showSearchBar,
  isScrolled,
  showAboutUs,
  unreadNotificationsCount,
  setShowNavbarModal,
  showNavbarModal,
  setDropdownVisible,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();

  const handleLogin = () => {
    navigate('/prisijungimas', { state: { from: location.pathname } });
  };

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  const handleNavigate = (path: string) => {
    if (location.pathname === path) {
      window.location.href = path;
    } else {
      navigate(path);
    }
  };

  const buttonClass = showSearchBar
    ? 'nav-login-button'
    : isScrolled
    ? 'nav-login-button'
    : 'nav-login-button-white';

  const addListingClass = showSearchBar
    ? 'add-listing-button'
    : isScrolled
    ? 'add-listing-button'
    : 'add-listing-button-white';

  const menuButtonClass = showSearchBar
    ? 'navbar-menu-button'
    : isScrolled
    ? 'navbar-menu-button'
    : 'navbar-menu-button-white';

  return (
    <section className="authentication">
      <div className="button-group">
        <button
          className={addListingClass}
          onClick={() => handleNavigate('/skelbimai/paskelbti')}
        >
          Įdėti skelbimą
        </button>
        {(!(!showAboutUs && showSearchBar)) && (
          <button
            className={buttonClass}
            onClick={() => navigate('/apie-mus')}
          >
            Apie mus
          </button>
        )}
        {!isLoggedIn ? (
          <button className={buttonClass} onClick={handleLogin}>
            Prisijungti
          </button>
        ) : (
          <button
            className={menuButtonClass}
            onClick={() => setShowNavbarModal(true)}
          >
            <img className="nav-menu-img" src={manuImg} alt="Menu" />
            <img className="nav-profile-img" src={man} alt="Profile" />
            {unreadNotificationsCount > 0 && (
              <h4 className="unread-badge">{unreadNotificationsCount}</h4>
            )}
          </button>
        )}
      </div>
      {showNavbarModal && (
        <NavbarModal
          setShowNavbarModal={setShowNavbarModal}
          setDropdownVisible={setDropdownVisible}
          unreadNotificationsCount={unreadNotificationsCount}
          handleLogout={handleLogout}
        />
      )}
    </section>
  );
};

export default DesktopMenu;