// context/BlogsContext.tsx
import React, { createContext, useContext, useState, useCallback } from 'react';
import { useAuthApi } from '../utils/authApi';
import { Blog } from '../types';

interface BlogsContextProps {
  blogs: Blog[];
  totalBlogPages: number;
  fetchBlogs: (page: number) => Promise<void>;
  fetchBlogPostBySlug: (slug: string) => Promise<Blog | null>;
}

const BlogsContext = createContext<BlogsContextProps | undefined>(undefined);

export const BlogsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [totalBlogPages, setTotalBlogPages] = useState(0);

  const authApi = useAuthApi();

  const fetchBlogs = useCallback(async (page: number = 1) => {
    try {
      const response = await authApi.get(`/api/blogs?page=${page}`, {}, { requiresAuth: false });
      setBlogs(response.data.blogs);
      setTotalBlogPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching blogs:", error);
      setBlogs([]);
      setTotalBlogPages(0);
    }
  }, []);

  const fetchBlogPostBySlug = useCallback(async (slug: string): Promise<Blog | null> => {
    try {
      const response = await authApi.get(`/api/blogs/${slug}`, {}, { requiresAuth: false });
      return response.data;
    } catch (error) {
      console.error(`Error fetching blog post with slug ${slug}:`, error);
      return null;
    }
  }, []);

  return (
    <BlogsContext.Provider value={{ blogs, totalBlogPages, fetchBlogs, fetchBlogPostBySlug }}>
      {children}
    </BlogsContext.Provider>
  );
};

export const useBlogs = () => {
  const context = useContext(BlogsContext);
  if (context === undefined) {
    throw new Error('useBlogs must be used within a BlogsProvider');
  }
  return context;
};