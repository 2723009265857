// components/PaginationNotifications.tsx

import React, { useState, useEffect } from 'react';

interface PaginationNotificationsProps {
    totalPageCount: number;
    onPageChange: (page: number) => void;
    currentPage: number;
}

const PaginationNotifications: React.FC<PaginationNotificationsProps> = ({ totalPageCount, onPageChange, currentPage }) => {
    return (
        <div className="pagination-notifications">
            <button className={currentPage > 1?'':'invisible-button'} onClick={() => onPageChange(currentPage - 1)}>{'<'} ATGAL</button>
            
            <span>{currentPage} / {totalPageCount}</span>

            <button className={currentPage < totalPageCount?'':'invisible-button'} onClick={() => onPageChange(currentPage + 1)}>PIRMYN {'>'}</button>
        </div>
    );
}

export default PaginationNotifications;