// components/Hamburger.tsx
import React from "react";
import { useAuth } from "../context/AuthStatusContext";
import { useNavigate } from "react-router-dom";
import "../styles/Hamburger.css";

interface MenuProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  whiteLines?: boolean;
  unreadNotificationsCount: number;
}

const BurgerMenu: React.FC<MenuProps> = ({
  children,
  open,
  onClose,
  whiteLines,
  unreadNotificationsCount,
}: MenuProps) => {
  const { isLoggedIn, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate("/");
  };

  return (
    <div className="nbar">
      <div className="container nbar-container">
        <input className="checkbox" type="checkbox" name="hamburger-toggle" id="hamburger-toggle" />
        <label htmlFor="hamburger-toggle" className="hamburger-visually-hidden">Atidaryti menu</label>
        <div className="hamburger-lines">
          <span className={`line line1${whiteLines ? " w" : ""}`}></span>
          <span className={`line line2${whiteLines ? " w" : ""}`}></span>
          <span className={`line line3${whiteLines ? " w" : ""}`}></span>
        </div>
        <nav className="menu-items">
          <ul>
            {!isLoggedIn && (
              <>
                <li>
                  <a href="/skelbimai/paskelbti" rel="nofollow">Įdėti skelbimą</a>
                </li>
                <li>
                  <a href="/prisijungimas" rel="nofollow">Prisijungti</a>
                </li>
                <li>
                  <a href="/registracija">Registruotis</a>
                </li>
                <li>
                  <a href="/apie-mus">Apie mus</a>
                </li>
              </>
            )}
            {isLoggedIn && (
              <>
                <li>
                  <a href="/skelbimai/paskelbti">Įdėti skelbimą</a>
                </li>
                <li>
                  <a href="/vartotojas/mano-skelbimai">Mano skelbimai</a>
                </li>
                <li>
                  <a href="/vartotojas">Profilis</a>
                </li>
                <li>
                  <a href="/vartotojas/skydelis">
                    Pranešimai
                    {unreadNotificationsCount > 0 && (
                      <span className="unread-badge burger item">
                        {unreadNotificationsCount}
                      </span>
                    )}
                  </a>
                </li>
                <li>
                  <a href="/apie-mus">Apie mus</a>
                </li>
                <li>
                  <button onClick={handleLogout}>Atsijungti</button>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default BurgerMenu;