// components/NotificationsDropdown.tsx
import React from 'react';
import NotificationsComponent from './NotificationsComponent';

type NotificationsDropdownProps = {
  setDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>;
  updateAllToRead: () => void;
};

const NotificationsDropdown: React.FC<NotificationsDropdownProps> = ({
  setDropdownVisible,
  updateAllToRead,
}) => {
  return (
    <div
      className="notifications-popup-overlay"
      onClick={() => {
        updateAllToRead();
        setDropdownVisible(false);
      }}
    >
      <div
        className="notification-dropdown"
        onClick={(event) => event.stopPropagation()}
      >
        <NotificationsComponent limited={true} />
      </div>
    </div>
  );
};

export default NotificationsDropdown;