// contexts/AuthStatusContext.tsx
import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { executeRecaptcha } from '../utils/recaptchaUtils';
import { OwnerType } from '../types';

interface AuthContextProps {
  isLoggedIn: boolean;
  user: OwnerType | null;
  loadingAuth: boolean;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
  checkAuthenticationStatus: () => Promise<void>;
  setUser: React.Dispatch<React.SetStateAction<OwnerType | null>>;
}

const AuthStatusContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthStatusProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<OwnerType | null>(null);
  const [loadingAuth, setLoadingAuth] = useState(true);

  const login = useCallback(async (email: string, password: string): Promise<boolean> => {
    try {
      const recaptchaToken = await executeRecaptcha('login');
      const response = await axios.post<{ user: OwnerType }>('/api/users/login', { email, password }, {
        headers: { 'g-recaptcha-response': recaptchaToken },
        withCredentials: true,
      });

      if (response.status === 200 && response.data.user) {
        setIsLoggedIn(true);
        setUser(response.data.user);
        return true;
      }
    } catch (error) {
      console.error('Login error:', error);
    }
    return false;
  }, []);

  const logout = useCallback(async () => {
    try {
      await axios.post('/api/users/logout', {}, { withCredentials: true });
    } catch (err) {
      console.error('Logout error:', err);
    } finally {
      setIsLoggedIn(false);
      setUser(null);
    }
  }, []);

  const checkAuthenticationStatus = useCallback(async () => {
    setLoadingAuth(true);
    try {
      const response = await axios.get<{ user: OwnerType | null }>('/api/users/checkAuth', { withCredentials: true });
      if (response.data.user) {
        setIsLoggedIn(true);
        setUser(response.data.user);
      } else {
        setIsLoggedIn(false);
        setUser(null);
      }
    } catch (error) {
      // console.error('Auth check error:', error);
      setIsLoggedIn(false);
      setUser(null);
    } finally {
      setLoadingAuth(false);
    }
  }, []);

  useEffect(() => {
    checkAuthenticationStatus();
  }, [checkAuthenticationStatus]);

  const contextValue = useMemo(() => ({
    isLoggedIn,
    user,
    loadingAuth,
    login,
    logout,
    checkAuthenticationStatus,
    setUser
  }), [isLoggedIn, user, loadingAuth, login, logout, checkAuthenticationStatus]);

  return (
    <AuthStatusContext.Provider value={contextValue}>
      {children}
    </AuthStatusContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthStatusContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};