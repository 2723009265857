// context/ListingContext.tsx
import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useFilters } from './FilterContext';
import { debounce } from 'lodash';
import { LocatedListing } from '../types';
import { useAuthApi } from '../utils/authApi';
import { convertBoundsToArray } from '../utils/mapUtils';

interface ListingsContextProps {
    listing: LocatedListing | null;
    loadSingleListing: (id: number) => Promise<void>;
    visibleListings: LocatedListing[];
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    loadListingsByTopKeywordName: (keywordName: string) => Promise<LocatedListing[]>;
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    totalPageCount: number;

}
interface ListingsProviderProps {
    children: React.ReactNode;
}

const ListingsContext = createContext<ListingsContextProps>({
    listing: null,
    loadSingleListing: async () => { },
    visibleListings: [],
    loading: false,
    setLoading: () => { },
    loadListingsByTopKeywordName: async () => [],
    currentPage: 1,
    setCurrentPage: () => { },
    totalPageCount: 0,

});

export const useListings = () => useContext(ListingsContext);

export const ListingsProvider: React.FC<ListingsProviderProps> = ({ children }) => {
    const { uniqueEntry, minPrice, maxPrice, minRating, topLevelKeyword, keyword, lowLevelKeywords, cityId, isBusiness, locationIds, currentBounds } = useFilters();

    const [listing, setListing] = useState<LocatedListing | null>(null);
    const [visibleListings, setVisibleListings] = useState<LocatedListing[]>([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(1);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const { get } = useAuthApi();

    const loadListingsByTopKeywordName = async (keywordName: string) => {
        try {
            const response = await get(`/api/listings/filtered-listings`, {
                params: { topLevelKeyword: keywordName }
            }, { requiresAuth: false });

            return response.data.listings;
        } catch (error) {
            console.error('Error fetching listings by keyword', error);
            return [];
        }
    };

    const loadSingleListing = useCallback(async (listingId: number) => {
        try {
            const response = await get(`/api/listings/${listingId}`, {}, { requiresAuth: false });
            const data: LocatedListing = response.data;

            setListing(data as LocatedListing);
        } catch (err: any) {
            setError(err.message);
        }
    }, []);

    const loadAllListings = async () => {
        setLoading(true);

        console.log(`Filters:
            uniqueEntry= ${uniqueEntry}, 
            cityId= ${cityId}, 
            locationIds= ${locationIds},
            minPrice=${minPrice}, 
            maxPrice=${maxPrice}, 
            minRating=${minRating}, 
            topLevelKeyword=${topLevelKeyword}, 
            keyword=${keyword}, 
            lowLevelKeyword=${lowLevelKeywords},
            isBusiness=${isBusiness}, 
            pageNum=${currentPage},
        `);

        try {
            const response = await get('/api/listings/filtered-listings', {
                uniqueEntry,
                minPrice, maxPrice,
                minRating,
                topLevelKeyword, keyword, lowLevelKeywords,
                cityId,
                locationIds,
                isBusiness,
                pageNum: currentPage,
                mapBounds: JSON.stringify(convertBoundsToArray(currentBounds)),
            }, { requiresAuth: false });

            await setTotalPageCount(response.data.pages.totalPageCount);

            // backend fetches listings of currentPage
            await setVisibleListings(response.data.listings);
        } catch (error) {
            console.error('Error loading more listings', error);
        } finally {
            setLoading(false);
        }
    };

    const debouncedLoadListings = useCallback(debounce(() => {
        loadAllListings();
    }, 400), [uniqueEntry, minPrice, maxPrice, minRating, topLevelKeyword, keyword, lowLevelKeywords, cityId, locationIds, isBusiness, currentPage, currentBounds]);

    useEffect(() => {
        debouncedLoadListings();
    }, [currentPage, debouncedLoadListings]);

    useEffect(() => {
        return () => {
            debouncedLoadListings.cancel();  // cancel any pending execution
        }
    }, [debouncedLoadListings]);

    // ensuring to remove old listings and reset pagination
    useEffect(() => {
        setVisibleListings([]);
        setCurrentPage(1);
        debouncedLoadListings();
    }, [uniqueEntry, minPrice, maxPrice, minRating, topLevelKeyword, keyword, lowLevelKeywords, cityId, locationIds, isBusiness, currentBounds]);

    return (
        <ListingsContext.Provider value={{
            listing, loadSingleListing,
            visibleListings,
            loading, setLoading,
            loadListingsByTopKeywordName,
            currentPage, setCurrentPage, totalPageCount,
        }}>
            {children}
        </ListingsContext.Provider>
    );
};
