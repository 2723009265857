// utils/filterCallsHTTP.ts
import axios from 'axios'; // negalima daryt su authApi nes top level
import { City } from '../types';

export const fetchTopLevelKeywords = async (): Promise<string[]> => {
    const response = await axios.get('/api/listings/topLevelKeywords');
    return response.data;
}

export const fetchMainLevelKeywords = async (topLevelKeyword: string): Promise<string[]> => {
    const response = await axios.get(`/api/listings/keywords?topLevelKeyword=${topLevelKeyword}`);
    return response.data;
}

export const fetchLowLevelKeywords = async (topLevelKeyword: string): Promise<string[]> => {
    const response = await axios.get(`/api/listings/lowLevelKeywords?topLevelKeyword=${topLevelKeyword}`);
    return response.data;
}

export const fetchCities = async (): Promise<City[]> => {
    const response = await axios.get('/api/listings/cities');
    return response.data;
}