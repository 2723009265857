// context/FavoritesContext.tsx
import React, { createContext, useContext, useEffect, useCallback, useState } from 'react';
import { useAuthApi } from '../utils/authApi';
import { Listing } from '../types';
import { useAuth } from './AuthStatusContext';

interface FavoritesContextProps {
  favoriteListings: Listing[];
  setFavoriteListings: React.Dispatch<React.SetStateAction<Listing[]>>;
  loadingFavorites: boolean;
  fetchFavoriteListings: () => Promise<void>;
}

const FavoritesContext = createContext<FavoritesContextProps | undefined>(undefined);

export const FavoritesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [favoriteListings, setFavoriteListings] = useState<Listing[]>([]);
  const [loadingFavorites, setLoadingFavorites] = useState(false);
  const authApi = useAuthApi();
  const { isLoggedIn } = useAuth();

  const fetchFavoriteListings = useCallback(async () => {
    setLoadingFavorites(true);
    try {
      const response = await authApi.get('/api/favorites', { withCredentials: true });
      setFavoriteListings(response.data);
    } catch (error) {
      // console.error("Error fetching favoriteListings:", error);
      setFavoriteListings([]);
    } finally {
      setLoadingFavorites(false);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    fetchFavoriteListings();
  }, [fetchFavoriteListings, isLoggedIn]);

  return (
    <FavoritesContext.Provider value={{ favoriteListings, setFavoriteListings, loadingFavorites, fetchFavoriteListings }}>
      {children}
    </FavoritesContext.Provider>
  );
};

export const useFavorites = () => {
  const context = useContext(FavoritesContext);
  if (context === undefined) {
    throw new Error('useFavorites must be used within a FavoritesProvider');
  }
  return context;
};