// App.tsx
import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import { setupAxiosInterceptors } from './utils/axiosSetup';
import { AuthStatusProvider, useAuth } from './context/AuthStatusContext';
import { SearchProvider } from './context/SearchContext';
import { ListingsProvider } from './context/ListingContext';
import { FilterProvider } from './context/FilterContext';
import { CityProvider } from './context/CityContext';
import { NotificationsProvider } from './context/NotificationsContext';
import { FavoritesProvider } from './context/FavoritesContext';
import { BlogsProvider } from './context/BlogsContext';


import NavbarAndFooter from './layouts/NavbarAndFooter';
import CookiesBar from './components/CookiesBar';
import LoadingIndicator from './components/LoadingIndicator';

// Lazy load components
const Register = lazy(() => import('./pages/Register'));
const Login = lazy(() => import('./pages/Login'));
const Home = lazy(() => import('./pages/Home'));
const Listing = lazy(() => import('./pages/Listing'));
const Listings = lazy(() => import('./pages/Listings'));
const ListingsSubmit = lazy(() => import('./pages/ListingsSubmit'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const NewPassword = lazy(() => import('./pages/NewPassword'));
const Client = lazy(() => import('./pages/Client'));
const Info = lazy(() => import('./pages/Info'));
const MyListings = lazy(() => import('./pages/MyListings'));
const Panel = lazy(() => import('./pages/Panel'));
const VerifyAccount = lazy(() => import('./pages/VerifyAccount'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const Blog = lazy(() => import('./pages/Blog'));
const Blogs = lazy(() => import('./pages/Blogs'));


declare const grecaptcha: any;

function MainComponent() {
  const { logout } = useAuth();

  React.useEffect(() => {
    setupAxiosInterceptors(logout);
  }, [logout]);

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Routes>

        <Route path="/registracija" element={<Register />} />
        <Route path="/verify-account/:token" element={<VerifyAccount />} />
        <Route path="/prisijungimas" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-password/:token" element={<NewPassword />} />


        <Route element={
          <FilterProvider>
            <SearchProvider>
              <NotificationsProvider>
                <NavbarAndFooter />
              </NotificationsProvider>
            </SearchProvider>
          </FilterProvider>
        }>
          <Route path="/" element={<Home />} />
          <Route path="/privatumo-politika" element={<PrivacyPolicy />} />
          <Route path="/naudojimosi-taisykles" element={<TermsAndConditions />} />
          <Route path="/apie-mus" element={<AboutUs />} />
          
          <Route path="/skelbimai" element={
            <CityProvider>
              <FavoritesProvider>
                <ListingsProvider>
                  <Outlet />
                </ListingsProvider>
              </FavoritesProvider>
            </CityProvider>
          }>
            <Route index element={<Listings />} />
            <Route path=":slug" element={<Listing />} />
            <Route path="paskelbti" element={<ListingsSubmit />} />
          </Route>

          <Route path="/vartotojas" element={
              <Outlet />
          }>
            <Route index element={<Client />} />
            <Route path="info" element={<Info />} />
            <Route path="mano-skelbimai" element={<MyListings />} />
            <Route path="skydelis" element={
              <FavoritesProvider>
                <Panel />
              </FavoritesProvider>
            } />
          </Route>

          <Route path="/straipsniai" element={
            <BlogsProvider>
              <Outlet />
            </BlogsProvider>
          }>
            <Route index element={<Blogs />} />
            <Route path=":slug" element={<Blog />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}

function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      // console.log('reCAPTCHA library loaded.');
      grecaptcha.ready(() => {
        // console.log('reCAPTCHA is ready.');
      });
    };
    document.head.appendChild(script);
  }, []);


  return (
    <Router>
      <AuthStatusProvider>
        <MainComponent />
        <CookiesBar />
      </AuthStatusProvider>
    </Router>
  );
}

export default App;